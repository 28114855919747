import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ITEM_PER_PAGE } from '../../constants';
import { getResource } from '../../services/api';
import { AppContext } from '../layout'
import moment from 'moment'
import FilAriane from '../layout/ariane';

function Documents() {

    const [documents, setDocuments] = useState([]);
    const [years, setYears] = useState([]);
    const [filter, setFilter] = useState({ year: null, order: 'desc' });
    const [parent_menu, setParentMenu] = useState('');
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)

    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])



    useEffect(() => {
        loadData()
    }, [filter, search, currentPage, slug]);

    const loadData = () => {
        setYears(anneeGenerator())
        getResource(`documents?order[annee]=${filter.order}${filter.year ? `&annee=${filter.year}` : ''}&itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&libelle=${search}` : ''}&module.libUnique=${slug}`).then(res => {
            setDocuments(res.data['hydra:member'])
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setAppState({ ...appState, parent_menus: parent })
        })

        getResource(`menus/${parent}`).then(res => {
            console.log(res.data);
            setParentMenu(res.data.name)
        })


    }



    const anneeGenerator = () => {
        let year = []
        for (let index = 2000; index <= parseInt(moment().format('YYYY')); index++) {
            year.push(index)

        }
        return year
    }

    const changeAnnee = (data) => {
        setFilter({ ...filter, year: data.target.value })
    }

    const ariane = {
        primary: { title: 'ACCUEIL', url: '/' },
        secondary: { title: parent_menu && parent_menu },
        tertiary: { title: slug },
    }

    return (
        <>
            <div className="col-md-12">
                <div className="page-info">
                    {slug && <h2 className="text-center text-white text-uppercase">{slug}</h2>}
                    {parent_menu && slug && <FilAriane ariane={ariane} />}
                </div>
            </div>
            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded mb-3">
                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>
                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <div className="shadow">
                            <div className="p-3  bckColorDark text-center"><h6 className="text-white">Filtrage</h6></div>
                            <div className="bg-light p-2 ">
                                <label className="text-start mt-2">Filtre par année</label>
                                <select name="" onChange={(event) => changeAnnee(event)} className="form-control mt-2" value={filter.year}>
                                    <option value={null}  >--Toutes les années--</option>
                                    {
                                        years.map(a => (
                                            <option key={a} value={a}>{a}</option>
                                        ))
                                    }
                                </select>
                                <label className="text-start mt-2" >Ou par</label>
                                <select name="order_select" className="form-control mt-2" onChange={(event) => setFilter({ ...filter, order: event.target.value })} value={filter.order}>
                                    <option value="desc">Les plus recents</option>
                                    <option value="asc">Les moins recents</option>
                                    {/* <option value="">Les plus consultés</option> */}
                                </select>
                                {/* <div className="text-center">
                                    <button className="btn btn-success mt-3 text-center mx-auto"><i className="fas fa-filter"></i>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-9">
                        <div className="shadow">
                            <div className="list-group">
                                <div className="d-flex w-100 justify-content-between bckColorDark p-3">
                                    <div>
                                        <h6 className="mb-1 xs-font text-white">Libellé | Date</h6>
                                    </div>
                                    <div className="w-25">
                                        <h6 className="mb-1 me-3 xs-font text-white float-end">Actions</h6>
                                    </div>
                                </div>

                                {
                                    documents.map(d => (
                                        <a href={process.env.REACT_APP_API_BASE_URL + '/' + d.fichier.contentUrl} key={d.id} className="list-group-item list-group-item-action">
                                            <div className="d-flex w-100 justify-content-between">
                                                <div>
                                                    <h6 className="mb-1">{d.libelle}</h6>
                                                    <p><small className="text-muted">Publié le {moment(d.createdAt).format('DD MMMM YYYY')}</small></p>
                                                    <p><small className="text-muted">{d.annee ? d.annee : '-'}</small></p>
                                                </div>
                                                <div className="w-25">
                                                    <div className="document-dbtn">
                                                        <a href={process.env.REACT_APP_API_BASE_URL + '/' + d.fichier.contentUrl} className="btn btn-sm btn-outline-warning text-dark "
                                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </>
    )
}

export default Documents;